<template>
  <v-card>
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              class="workstation-setting-wkst-text-field"
              id="workstationSettingWkstTextField"
              v-model="editItem.wkst"
              label="Workstation ID"
              :rules="wkstValidation"
              hint="please enter numbers only"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>

    <v-card-actions>
      <v-spacer></v-spacer>
        <v-btn
          class="workstation-setting-submit-btn"
          id="workstationSettingSubmitBtn"
          depressed
          color="primary"
           @click="update"
        >
          Save
        </v-btn>
    </v-card-actions>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { setWorkstationNumber } from '../../../util/workstation-util';

export default {
  name: 'WorkstationSetting',
  data: () => ({
    editItem: {
      wkst: undefined,
    },
  }),
  computed: {
    wkstValidation() {
      return [
        (value) => /^(?!0+$)\d+$/.test(value) || 'Please enter numbers > 0 only',
      ];
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapActions(['flashSuccess']),
    ...mapActions('SystemConfig', [
      'fetchAndSetWkst',
    ]),
    ...mapMutations('auth/token', [
      'setLoggedOut',
    ]),
    async initData() {
      this.editItem = { ...(await this.fetchAndSetWkst({})) };
    },
    update() {
      if (this.$refs.form.validate()) {
        setWorkstationNumber(window, this.editItem.wkst);
        this.fetchAndSetWkst(this.editItem);
        this.flashSuccess('Workstation ID Successfully Saved, please sign in again');
        /*
          As we are handling wkst with token,
          thats we are forcefully logout so on login, user can get updated wkst in token
        */
        this.$nextTick(() => {
          this.setLoggedOut();
        });
      }
    },
  },
};
</script>
