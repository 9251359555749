<template>
  <v-card>
    <v-card-title>
      <span class="headline">User-level Settings</span>
    </v-card-title>
    <v-card-subtitle>{{user}}</v-card-subtitle>

    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col
            cols="6" sm="6" md="4"
          >
            <span>Light / dark theme </span>
            <span class="font-weight-thin"></span>
            <v-select
              :items="lightdarkitems"
              v-model="lightdark"
              label="Light / Dark mode"
              solo />
          </v-col>
          <v-col
            cols="6" sm="6" md="4"
          >
            <span>Items per page </span>
            <span class="font-weight-thin"></span>
            <v-select
              :items="itemsperpageitems"
              v-model="itemsperpage"
              label="Items per page"
              solo />
          </v-col>
          <v-col
            cols="6" sm="6" md="4"
          >
            <v-checkbox
              small class="mr-2"
              v-model="betaFlag"
              label="Beta feature(s) flag 🚧"
            ></v-checkbox>
          </v-col>
        </v-row>
        <h3>Reset Password</h3>
        <br/>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="userData.currentPassword"
              label="Current Password"
              type="password"
              id="updateProfileOldPass"
              name="updateProfileOldPass"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="userData.newPassword"
              label="New Password"
              type="password"
              id="updateProfileNewPass"
              name="updateProfileNewPass"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="userData.confirmPassword"
              label="Confirm Password"
              type="password"
              id="updateProfileConfPass"
              name="updateProfileConfPass"
              autocomplete="new-password"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        text color="blue darken-1"
        @click="update"
      >
        Update
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
// import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'UserSettings',
  mixins: [validationMixin],
  validations: {
  },
  data: () => ({
    lightdark: undefined,
    itemsperpage: undefined,
    betaFlag: undefined,
    userData: {},
  }),
  computed: {
    lightdarkitems() {
      return ['light', 'dark'];
    },
    itemsperpageitems() {
      return [-1, 10, 25, 50, 100, 200];
    },
    inputs() {
      return {
        lightdark: this.lightdark,
        itemsperpage: this.itemsperpage,
        betaFlag: this.betaFlag,
      };
    },
    ...mapState({
      user: (state) => state.auth.user,
    }),
    ...mapGetters('user', [
      'getSettings',
    ]),
  },
  mounted() {
    this.lightdark = this.getSettings.lightdark;
    this.itemsperpage = this.getSettings.itemsperpage;
    this.betaFlag = this.getSettings.betaFlag;
  },
  methods: {
    update() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.updateSettings(this.inputs);
      }

      // reset user's password
      if (this.userData.currentPassword) {
        const updatedData = { ...this.userData };
        updatedData.isPasswordNotRequired = false;
        this.$store.dispatch('UserAdministration/resetUsersPassword', updatedData);
        this.userData = {};
      } else {
        // To-do manage single submit button for multiple task
        setTimeout(() => {
          window.location.reload();
        }, 10);
      }
    },
    ...mapActions('user', [
      'updateSettings',
    ]),
  },
};
</script>
