<template>
  <v-container>
    <v-tabs>
      <v-tab>USER PROFILE</v-tab>
      <v-tab>WORKSTATION</v-tab>

      <v-tab-item>
        <UserSettings />
      </v-tab-item>

      <v-tab-item>
        <WorkstationSetting />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import UserSettings from './UserSettings.vue';
import WorkstationSetting from './WorkstationSetting.vue';

export default {
  name: 'ProfileSettings',
  components: {
    UserSettings,
    WorkstationSetting,
  },
};
</script>
